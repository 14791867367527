@tailwind base;
@tailwind components;
@tailwind utilities;

$primary: #3A84FF;
$radius: 12px;

body {
  font-family: 'Montserrat' !important;
  input, textarea {
    &:focus {
      box-shadow: none !important;
    }
  }
}

.calendar {
  &--days {    
    span {
      width: 14.28571%;
      display: inline-block;
      text-align: center;
      user-select: none;
      cursor: pointer;
      line-height: 30px;
      position: relative;
      font-size: 12px;

      &.label {
        color: rgb(158, 158, 158);
      }


      &.muted {
        color: rgba(0, 0, 0, 0.3);
      }

      &.start, &.end {
        color: #fff;
      }

      &.start {
        border-radius: $radius 0 0 $radius;
      }

      &.end {
        border-radius: 0 $radius $radius 0;
      }

      &.start.end {
        border-radius: $radius;
      }

      &.between:nth-child(7n):after, &.start:nth-child(7n):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background-color: #EFEFEF;
        width: 20px;
      }

      &.between:nth-child(7n + 1):after, &.end:nth-child(7n + 1):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 20px;
        background-color: #EFEFEF;
      }

      &.start.end:after {
        display: none;
      }
    }
  }
  &--months {    
    span {
      width: 21%;
      margin: 5px 2%;
      display: inline-block;
      text-align: center;
      user-select: none;
      cursor: pointer;
      line-height: 30px;
      position: relative;
      font-size: 12px;

      &.label {
        color: rgb(158, 158, 158);
      }


      &.muted {
        color: rgba(0, 0, 0, 0.3);
      }

      &.start, &.end {
        color: #fff;
      }

      &.start {
        border-radius: $radius 0 0 $radius;
      }

      &.end {
        border-radius: 0 $radius $radius 0;
      }

      &.start.end {
        border-radius: $radius;
      }

      &.between:nth-child(4n):after, &.start:nth-child(4n):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background-color: #EFEFEF;
        width: 20px;
      }

      &.between:nth-child(4n + 1):after, &.end:nth-child(4n + 1):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 20px;
        background-color: #EFEFEF;
      }

      &.start.end:after {
        display: none;
      }
    }
  }
  &--years {
    span {
      width: 21%;
      margin: 5px 2%;
      display: inline-block;
      text-align: center;
      user-select: none;
      cursor: pointer;
      line-height: 30px;
      position: relative;
      font-size: 12px;

      &.label {
        color: rgb(158, 158, 158);
      }


      &.muted {
        color: rgba(0, 0, 0, 0.3);
      }

      &.start, &.end {
        color: #fff;
      }

      &.start {
        border-radius: $radius 0 0 $radius;
      }

      &.end {
        border-radius: 0 $radius $radius 0;
      }

      &.start.end {
        border-radius: $radius;
      }

      &.between:nth-child(4n):after, &.start:nth-child(4n):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        background-color: #EFEFEF;
        width: 20px;
      }

      &.between:nth-child(4n + 1):after, &.end:nth-child(4n + 1):after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 20px;
        background-color: #EFEFEF;
      }

      &.start.end:after {
        display: none;
      }
    }
  }
}

.copy-task-entity-container {
  .react-select__value-container {
    display: flex !important;
    flex-wrap: nowrap !important;
  }
}

@layer utilities {
  .typography {
    color: #606060;
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0.00938em;
    cursor: default;
    margin: 0;
  }
  .overview-notification-item {
    padding-top: 15px;
    padding-bottom: 15px;
    .message-item {
      padding-top: 0;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}